
import {React, useState, useEffect,useCallback} from "react";
import NavBar from "./Navbar";
 
import 'bootstrap/dist/css/bootstrap.css';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
 
import {Buffer} from 'buffer';
import CountryStatedata from "../../src/CountryState"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTwitter, faInstagram, faYoutube, faLinkedin} from "@fortawesome/free-brands-svg-icons"
import TidyCalEmbed from "./Tidycal";
import { CopyToClipboard } from "react-copy-to-clipboard";

 


function Info(props){

   const [Isvalucopy, setIsvaluecopy] = useState(false);
   const [Isvalucopy2, setIsvaluecopy2] = useState(false);
   
   const handleCopy = () =>{
     setIsvaluecopy(true);
   };

   useEffect(() =>{
    setTimeout(() => {
       if(Isvalucopy) setIsvaluecopy(false)
    },2000)
   },[Isvalucopy])


   const handleCopy2 = () =>{
    setIsvaluecopy2(true);
  };
  useEffect(() =>{
   setTimeout(() => {
      if(Isvalucopy2) setIsvaluecopy2(false)
   },2000)
  },[Isvalucopy2])

    return(
        <div className="row info-row">
        
               <div className="col-2 col-icons">
               <div className="img-div-info">
               <a href={props.href}><img alt="iconimg" src={props.src} className="icons-info-img"></img></a>
               </div>
                  </div>
                  <div className="col-10">
                  <div className="div-info-col"> 
                  <div className="row "><p className="title-info">{props.title}</p> </div>
                  <div className="row "><p  className="content-info">{props.contentTitle}</p><CopyToClipboard text={props.content}  onCopy={handleCopy}><p className="content-info" id={props.Id}> {props.content}  {Isvalucopy &&  ( <div className="div-copy-msg"><p className="copy-message">copied!</p></div>)}{""}</p></CopyToClipboard> <CopyToClipboard text={props.content2}  onCopy={handleCopy2}><p className="content-info" id={props.Id}> {props.content2}  {Isvalucopy2 &&  ( <div className="div-copy-msg"><p className="copy-message">copied!</p></div>)}{""}</p></CopyToClipboard> </div> 
                  </div>
                  </div>
        </div>
                
                
    );
}

function NavContact(props){
    return(
    
          <a className="icon-navContact" href={props.herf}><i className={props.iconClass}></i></a>
      
    );
}

 

function ContactForm(){
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [Countryid, setCountryid] = useState('');
    const [state, setState] = useState([]);
    const [stateid, setStateid] = useState('');
    const [countryName, setCounrtyName] = useState('');
    const [stateName, setStateName] = useState('');
    const [day, setday] = useState('');
    const [time, setTime] = useState('');
    const [checkedValues, setcheckedValues] = useState([]);
    const [message, setMessage] = useState('');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    

  const initialCheckboxes = [
    { id: 1,  name: " Niger Seed", value: " Niger Seed", selected: false, order: 1 },
    { id: 2, name: " Cotton Seed", value: " Cotton Seed", selected: false, order: 2 },
    { id: 3, name: " Sesame Seed", value: " Sesame Seed", selected: false, order: 3 },
    { id: 4, name: " Flax Seed", value: " Flax Seed", selected: false, order: 4 },
    { id: 5, name: " Soyabean", value: " Soyabean", selected: false, order: 5 },
     { id: 6, name: " Castor Seed", value: " Castor Seed", selected: false, order: 7 },
    { id: 7, name: " Mustard Seed", value: " Mustard Seed", selected: false, order: 8 },
    { id: 8, name: " Avocado", value: " Avocado", selected: false, order: 9 },
    { id: 9, name: " Niger Seed Edible Oil", value: " Niger Seed Edible Oil", selected: false, order: 10 },
    { id: 10, name: " Niger Seed Refined Oil", value: " Niger Seed Refined Oil", selected: false, order: 11 },
    { id: 11, name: " Sesame Seed Edible Oil", value: " Sesame Seed Edible Oil", selected: false, order: 12 },
    { id: 12, name: " Flax Seed Edible Oil", value: " Flax Seed Edible Oil", selected: false, order: 13 },
    { id: 13, name: " Flax Seed Refined Oil", value: " Flax Seed Refined Oil", selected: false, order: 14},
     { id: 14, name: " Castor Seed refined oil ", value: " Castor Seed refined oil", selected: false, order: 17},
    { id: 15, name: " Mustard Seed Edible Oil", value: " Mustard Seed Edible Oil", selected: false, order: 18},
    { id: 16, name: " Avocado Edible Oil", value: " Avocado Edible Oil", selected: false, order: 19 },
    { id: 17, name: " Avocado Butter", value: " Avocado Butter", selected: false, order: 20},
    // ... Add more initial checkboxes as needed
  ];

  const [checkboxes, setCheckboxes] = useState(initialCheckboxes);
  const [visibleCheckboxes, setVisibleCheckboxes] = useState(10);

  const handleCheckboxChange = (id) => {
    const checkboxIndex = checkboxes.findIndex(
      (checkbox) => checkbox.id === id
    );
    const checkbox = checkboxes[checkboxIndex];

    const updatedCheckbox = { ...checkbox, selected: !checkbox.selected };

    const updatedCheckboxes = [
      ...checkboxes.slice(0, checkboxIndex),
      updatedCheckbox,
      ...checkboxes.slice(checkboxIndex + 1)
    ];

    // Move the selected checkbox to the top
    const selectedCheckboxes = updatedCheckboxes.filter(
      (checkbox) => checkbox.selected
    );
    const unselectedCheckboxes = updatedCheckboxes.filter(
      (checkbox) => !checkbox.selected
    );

    const reorderedCheckboxes = [
      ...selectedCheckboxes.reverse(),
      ...unselectedCheckboxes
    ].map((checkbox, index) => ({
      ...checkbox,
      order: index + 1
    }));

    setCheckboxes(reorderedCheckboxes);
  };

  const handleLoadMore = () => {
    setVisibleCheckboxes((prevVisibleCheckboxes) => prevVisibleCheckboxes + 10);
  };



 
    
       

     function handleMessage(e){
        setMessage(e.target.value);
     }

    function handlePhoneNumberChange(value) {
        setPhone(value);
        // console.log(value);
      }

      function handelcountry(e){
        const getcountryname = e.target.value;
        
        
        const getStatedata = CountryStatedata.find(country=>country.country_name===getcountryname).states;
        setState(getStatedata);
        setCounrtyName(getcountryname);

       
        // console.log(getcountryname);


      }

      function handelState(e){
        const getstatename = e.target.value;
        setStateName(getstatename);
        // console.log(getstatename); 
      }
     
 
 
     
    const username= process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_PASSWORD;
   
    
 
    const encodedCredentials = Buffer.from(`${username}:${password}`).toString('base64');

     
    function handleSubmit(event){
          event.preventDefault();

          const selectedCheckboxValues = checkboxes
    .filter((checkbox) => checkbox.selected)
    .map((checkbox) => checkbox.value);
        
          if (email.trim() !== '') {
            // const selectedCheckboxValues = getSelectedCheckboxValues(checkboxes);
        fetch(process.env.REACT_APP_SHEETDBLINK, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Basic ${encodedCredentials}`
            },
    body: JSON.stringify({
        data: [
            {
                Name: name,
                Email: email,
                Phone: phone,
                Country: countryName,
                State: stateName,
                Preferred_day: day,
                Preferred_time: time,
                Products_of_interest: selectedCheckboxValues,
                Message:message
                
            }
        ]
    })
})
  .then((response) => response.json())
  .then((data) => {console.log(data);
    window.location.href = '#/Booking';
    // window.location.href = '#/about';
  // <TidyCalEmbed/>
  }
  
  );
    
 
    
}
  setName('');
  setEmail('');
  setPhone('');
  setCounrtyName('');
  setStateName('');
  setday('');
  setTime('');
  // setSelectedCheckboxes('');
  // selectedCheckboxValues('');
  setMessage('');
 
    }
return(
  
    <section id="contact-form">
        <NavBar/>
        <form onSubmit={handleSubmit}> 
        <div className="container-fluid-form"> 
         <div className="row outer-row-form">
              <div className="col-lg-4 col-md-12 col-sm-12 col-contact-footer">
                <Info   Id="email-info-copy" src="images/3.png" title="Chat with us" contentTitle="We’re pleased to help at" content="​info@yewahorganics.com"/>
                <Info  Id="call-num-copy" src="images/1.png" title="Call us" contentTitle="Let’s talk on" content="​+251 901-005-232" content2="+1 202-888-3643"/>
                <Info Id="location-copy" src="images/2.png" title="Visit us" content="Addis Ababa, Ethiopia"/>
                 
                
                <div className="row row-world "  align="center">
                <div className="world-img-div">
                     <img alt="world" src="images/World Map with location pin.svg" className="world-img"></img> 
                </div>
               </div>
            
 
                <div className="row footer-row " align="center">
                <div className="icons-footer-contact"> 
                
            
            <a className="btn btn-outline-dark  btn-floating m-1" href="https://twitter.com/yewahorganics" target="_blank" role="button"
              ><FontAwesomeIcon icon={faTwitter} /></a>
      
            
            <a className="btn btn-outline-dark  btn-floating m-1" href="https://www.instagram.com/yewahorganics/" target="_blank" role="button"
              ><FontAwesomeIcon icon={faInstagram} /></a>
      
       
            <a className="btn btn-outline-dark  btn-floating m-1" href="https://www.linkedin.com/company/yewah-organics" target="_blank" role="button"
              ><FontAwesomeIcon icon={faLinkedin} /></a>
      
           
            <a className="btn btn-outline-dark  btn-floating m-1" href="https://www.youtube.com/@YewahOrganics" target="_blank" role="button"
              ><FontAwesomeIcon icon={faYoutube} /></a>

           
               </div>
                </div>
              </div>
              
          

              <div className=" col-lg-8 col-md-12 col-sm-12">
                <div className="box-form">
                <div className="content-form"> 
                <div className="content-before-button"> 
                     <h1>Ready for us to Contact You?</h1>
                     <h6>We just need some informations</h6>
                     <div className="row">
                     <div className="col">
                         <input placeholder="Name" className="css-inputs input-css-n-e" onChange={(e)=>setName(e.target.value)} value={name}></input>
                         </div>
                     </div>

                     <div className="row">
                     <div className="col">
                         <input placeholder="Work Email" className="css-inputs input-css-n-e" onChange={(e)=>setEmail(e.target.value)} value={email} required></input>
                         </div>
                     </div>

                     <div className="row">
                      
                     <PhoneInput className="phoneInput "
                            placeholder="Enter phone number"
                            international
                            defaultCountry="US"
                            initialValueFormat="national"
                            value={phone}
                            onChange={handlePhoneNumberChange}/>
                         </div>
                     
                     
                     <div className="row">
                     <div className="col">
                         <select placeholder="country" className="css-inputs dropdown" onChange={handelcountry}>
                            <option value="">Select Country</option>
                            {
                                CountryStatedata.map((getcountry,index)=>(
                                    <option value={getcountry.country_name} key={index}>{getcountry.country_name}</option>
                                ))
                            }
                            
                         </select>
                         </div>
                         <div className="col">
                         <select placeholder="state" className="css-inputs dropdown" onChange={handelState}>
                         <option value="">Select State</option>
                         {
                             state.map((getstate, index)=>(
                                
                                <option value={getstate.state_name} key={index}>{getstate.state_name}</option>

                             ))
 
                         }
                          </select>
                         </div>
                     </div>
                      
                     <div className="row">
                     <div className="col">
                         <select placeholder="Prefered day of contact" className="css-inputs dropdown" onChange={(e)=>setday(e.target.value)} value={day}>
                         <option value="">Preferred day of contact..</option>
                         <option value="monday">Monday</option>
                         <option value="tuesday">Tuesday</option>
                         <option value="wednesday">Wednesday</option>
                         <option value="thursday">Thursday</option>
                         <option value="friday">Friday</option>
                         <option value="saturday">Saturday</option>
                         <option value="sunday">Sunday</option>

                         </select>
                         </div>
                         <div className="col">
                         <select placeholder="Prefered time of contact" className="css-inputs dropdown" onChange={(e)=>setTime(e.target.value)} value={time}>
                         <option value="">Preferred time of contact..</option>
                         <option value="9:00 AM - 9:30 AM">9:00 AM - 9:30 AM</option>
                         <option value="9:30 AM - 10:30 AM">9:30 AM - 10:30 AM</option>
                         <option value="10:30 AM - 11:00 AM">10:30 AM - 11:00 AM</option>
                         <option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</option>
                         <option value="1:00 PM - 2:30 PM">1:00 PM - 2:30 PM</option>
                         <option value="2:30 PM - 3:00 PM">2:30 PM - 3:00 PM</option>
                         <option value="3:00 PM - 4:00 PM">3:00 PM - 4:00 PM</option>
                         <option value="4:00 PM - 5:00 PM">4:00 PM - 5:00 PM</option>
                         <option value="5:00 PM - 5:30 PM">5:00 PM - 5:30 PM</option>
                         <option value="5:30 PM - 6:00 PM">5:30 PM - 6:00 PM</option>
                         <option value="6:00 PM - 7:30 PM">6:00 PM - 7:30 PM</option>
                         </select>
                         </div>
                     </div>

                     <h5>Products of interest</h5>
                     

                    
                        <div>
                        {checkboxes.slice(0, visibleCheckboxes).map((checkbox) => (
                                     
                                    <label key={checkbox.name} className="optiions" style={{margin:'8px'}}>
                                    <input
                                        type="checkbox"
                                        name={checkbox.name}
                                        value={checkbox.value}
                                        // onChange={handleCheckboxChange}
                                        // checked={selectedCheckboxes.includes(checkbox.value)}
                                        checked={checkbox.selected}
                                        onChange={() => handleCheckboxChange(checkbox.id)}
                                    />
                                    {checkbox.name}
                                    </label>
                                   
                                ))}
                                <br />
                                {/* <label className="loadmore" onClick={handleLoadMoreClick}>Load More</label> */}
                                {visibleCheckboxes < checkboxes.length && (
                                   <button className="btn-loadmore" onClick={handleLoadMore}>Load More</button>
                                          )}
                                <br />
                                {/* <div style={{marginTop:'10px'}}><p>selected products:  <span style={{color:'green'}}> {" " + selectedCheckboxes + " , "}</span></p></div>  */}
                            </div>





                    

                     <div className="textarea-div">
                        <textarea placeholder="Send us a message..." onChange={handleMessage} value={message}></textarea>
                       
                        </div>
                     </div>

                     
                     <button type="submit" className="btn btn-lg contact-button">Submit!</button>
                     
                </div>
              </div>
         </div>
        </div>
        </div>
        </form>
       
    </section>
   

);
}









export  {ContactForm,Info,NavContact};