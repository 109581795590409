import React,{useEffect, useState } from "react";
 
import About from "./About";
import Home from "./Home";
 
import Catalog from "./Catalog"
import {ContactForm} from "./ContactForm"
 
import CatalogPages from "./CatalogPage"
 
import TidyCalEmbed from "./Tidycal";
  
// import PreLoader from "./preload";
 

import { Route, Routes, BrowserRouter,useLocation } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";
import Scrolltop from "./Scrolltop"


function App(){
  // const { pathname } = useLocation();

  // useEffect(() => {
  //   window.scrollTo({top: 0, behavior: "smooth"});
  // }, [pathname]);


 

  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   // Simulate an async operation (e.g., fetching data) that takes some time
  //   setTimeout(() => {
  //     setLoading(false); // Set loading to false after the preload animation is complete
  //   }, 5500);
  // }, []);

  // useEffect(() => {
  //   // Initialize AOS library when the content is fully loaded
  //   if (!loading) {
  //     const AOS = require("aos");
  //     AOS.init();
  //   }
  // }, [loading]);


  //used
  // useEffect(() => {
  //   if (!loading) {
  //     setTimeout(() => {
  //       AOS.init();
  //     }, 100); // Delay the initialization to ensure the elements are rendered
  //   }
  // }, [loading]);
  
  

  return (
    <div>
      <Scrolltop/>
      {/* {loading ? (
        <PreLoader />
      ) : (
        <div> */}
          
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/catalog/:title" element={<CatalogPages />} />
            <Route path="/catalog" element={<Catalog />} />
            <Route exact path="/" element={<Home />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/Booking" element={<TidyCalEmbed />} />
            
          </Routes>
        </div>
      // )}
    // </div>
  );
}

export default App;