import {React , useState, useEffect}from "react";
import {Row, Col} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import 'react-vertical-timeline-component/style.min.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
 function AboutYewahNoImage(props){
 

    return(
        <section id="third-section" >
        <div className="container-fluid-sections">
          <div className="row" >
            <div className="col-lg-6 col-md-6 p-colm">
            <h1 className="h-big h-responsive Top-imageOr-title">{props.title1}</h1>
            <h1 className="h-big line h-responsive bottom-imageOr-title">{props.title2}</h1>
            </div>
   
            <div className="colm col-lg-6 col-md-6">
              
              <p className=" p-about">{props.paragraph1}</p>
              <p className=" p-about">{props.paragraph2}</p>
              <p className=" p-about">{props.paragraph3}</p>
               
   
            </div>
          </div>
        </div>
      </section>

    );
 }

 function AboutYewahNoImageAnimate(props){
  return(
      <section id="third-section" >
      <div className="container-fluid-sections">
        <div className="row" >
          <div className="col-lg-6 col-md-6 p-colm" data-aos="fade-up">
          <h1 className="h-big h-responsive Top-imageOr-title">{props.title1}</h1>
          <h1 className="h-big line h-responsive bottom-imageOr-title">{props.title2}</h1>
          </div>
 
          <div className="colm col-lg-6 col-md-6" data-aos="fade-down">
            
            <p className=" p-about">{props.paragraph1}</p>
            <p className=" p-about">{props.paragraph2}</p>
            <p className=" p-about">{props.paragraph3}</p>
             
 
          </div>
        </div>
      </div>
    </section>

  );
}
 function AboutYewahNoImageFlib(props){
  useEffect(() => {
    AOS.init();
  }, [])
  return(
      <section id="third-section">
      <div className="container-fluid-sections" id={props.id}>
        <div className="row">
          <div className="colm col-lg-6 col-md-6 row-content order-2 order-md-1  "  data-aos="fade-right">
            <p className=" p-about">{props.paragraph1}</p>
            <p className=" p-about">{props.paragraph2}</p>
            <p className=" p-about margin-bottom-p">{props.paragraph3}</p>
          </div>

          <div className="col-lg-6 col-md-6 p-colm order-1 order-md-2 " data-aos="fade-left">
          <h1 className="h-big-flib h-responsive Top-imageOr-title">{props.title1}</h1>
          <h1 className="h-big-flib line h-responsive bottom-imageOr-title">{props.title2}</h1>
          {/* <h1 className="h-big-flib line">{props.title2}</h1> */}
          </div>

        </div>
      </div>
    </section>

  );
}

 function VisionMison(props){
  return(
    <div className="container-fluid-sections-missionVission">
              <div className="row">

            <div className="col-lg-6 col-md-6  mission"  data-aos="fade-up-right">
            <h3 className="h-mission">{props.mision}</h3>
            <p className="p-mission">{props.paragraphM} <hr></hr></p>
            </div>
   
            <div className="col-lg-6 col-md-6 vision-mission" data-aos="fade-up-left">
            <h3 className="h-vision">{props.vision}</h3>
            <p className="p-vision">{props.paragraphV}  <hr></hr></p>
             
            </div>

          </div>
    </div>
  );
 }

 function RowNumbers(props){
  const [counterOn, setCounterOn] = useState(false);
   return(
     
    <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}> 
    <div>
    <h1  id={props.id} className="num-statistic" >{counterOn && <CountUp start={props.startNumber} end={props.EndNumber} duration={3} delay={0}/>}{props.sign}</h1>
    <p className="p-statistic">{props.title}</p>
    </div>
    </ScrollTrigger>
   );
 }

  

 function AboutYewahWithImage(props){
    return(
        <section id="third-section">
        <div className="container-fluid-sections" id={props.id}>
          <div className="row">
            <div className="col-lg-6 col-md-6  div-img" data-aos="flip-right">
              <img className="noChem-img" src="images/No Chemical.svg"/>
            </div>
   
            <div className="colm col-lg-6 col-md-6">
              <h1 className="h-big-chem h-responsive Top-imageOr-title">{props.title}</h1>
              <h1 className="h-big-chem h-responsive line bottom-imageOr-title">{props.title2}</h1>
              <p className="p-big p-about">{props.paragraph1}</p>
              <p className="p-big p-about">{props.paragraph2}</p>
            </div>
          </div>
        </div>
      </section>

    );
 }

 

function TimeLine(props){
  return(
    
      <VerticalTimeline  lineColor="#19A7CE" layout= "2-columns" className="vertical-timeline-element" >
       
        <VerticalTimelineElement className="vertical-timeline-element--education   " 
        iconStyle={{background:'#00337C', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
         <h4>Step 1: Visit the Yewah Organics website</h4>
         <p>to learn about our products and services.</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement className="vertical-timeline-element--education vertical-timeline-element" 
        iconStyle={{background:'#00337C', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
        <h4>Step 2: Contact our sales team</h4>
         <p>via phone, email or the website to express interest in purchasing our products.</p>

        </VerticalTimelineElement>

        <VerticalTimelineElement className="vertical-timeline-element--education vertical-timeline-element" 
        iconStyle={{background:'#00337C', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
         <h4>Step 3: Our sales team will provide a catalog</h4>
         <p>of available products, pricing information, and order requirements.</p>
         
        </VerticalTimelineElement>

        <VerticalTimelineElement className="vertical-timeline-element--education vertical-timeline-element" 
        iconStyle={{background:'#00337C', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
         <h4>Step 4: Select the products</h4>
         <p>you wish to purchase and place your order with our sales team.</p>

        </VerticalTimelineElement>

        <VerticalTimelineElement className="vertical-timeline-element--education vertical-timeline-element" 
        iconStyle={{background:'#00337C', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
         <h4>Step 5: Delivery process</h4>
          <p>Our team will provide you with the delivery schedule and logistics information.</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement className="vertical-timeline-element--education vertical-timeline-element" 
        iconStyle={{background:'#00337C', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
         <h4>Step 6: Receive your order</h4>
         <p>and confirm that it meets your expectations.</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement className="vertical-timeline-element--education vertical-timeline-element" 
        iconStyle={{background:'#00337C', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
         <h4>Step 7: Provide feedback</h4>
         <p>to our sales team to help us improve our products and services.</p>
        </VerticalTimelineElement>


      </VerticalTimeline>
  
  );
}



function TimeLine2(props){
  return(
    
      <VerticalTimeline  lineColor="#19A7CE" layout= "2-columns" className="vertical-timeline-element">
       
        <VerticalTimelineElement className="vertical-timeline-element--education   " 
        iconStyle={{background:'#7AA874', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
         <h4>Step 1: Visit the Yewah Organics website</h4>
         <p>to learn about our products and services.</p>
                 </VerticalTimelineElement>

        <VerticalTimelineElement className="vertical-timeline-element--education vertical-timeline-element" 
        iconStyle={{background:'#7AA874', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
        <h4>Step 2: Contact our international sales team</h4>
        <p>via phone (Whatsapp), email, or the use the website to book a consultation timeslot (Via Contact us page) to express interest in purchasing our products.</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement className="vertical-timeline-element--education vertical-timeline-element" 
        iconStyle={{background:'#7AA874', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
         <h4>Step 3: Our international sales team</h4>
         <p>will provide you with a catalog of available products, pricing information, and order requirements.</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement className="vertical-timeline-element--education vertical-timeline-element" 
        iconStyle={{background:'#7AA874', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
         <h4>Step 4: Select the products</h4>
         <p>you wish to purchase and place your order with our international sales team.</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement className="vertical-timeline-element--education vertical-timeline-element" 
        iconStyle={{background:'#7AA874', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
         <h4>Step 5: Our team will provide you with</h4>
         <p>the delivery schedule, logistics information, and any necessary documentation required for customs clearance.</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement className="vertical-timeline-element--education vertical-timeline-element" 
        iconStyle={{background:'#7AA874', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
         <h4>Step 6: Coordinate with a customs broker</h4>
         <p>in your country to ensure that all necessary import regulations are met.</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement className="vertical-timeline-element--education vertical-timeline-element" 
        iconStyle={{background:'#7AA874', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
         <h4>Step 7: Receive your order</h4>
         <p>and confirm that it meets your expectations.</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement className="vertical-timeline-element--education vertical-timeline-element" 
        iconStyle={{background:'#7AA874', transform:' scale(0.3)',
        borderradius:' 50%' }} contentArrowStyle={{boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} } 
        contentStyle={ {boxshadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'} }>
         <h4>Step 8: Provide feedback</h4>
         <p>to our international sales team to help us improve our products and services for future international clients.</p>
        </VerticalTimelineElement>


      </VerticalTimeline>
  
  );
}

 

export {AboutYewahNoImageAnimate,TimeLine, TimeLine2, AboutYewahNoImage, VisionMison, RowNumbers,AboutYewahNoImageFlib,AboutYewahWithImage};