import React from "react";
import CatalogItem from "./CatalogItem";
 
import 'bootstrap/dist/css/bootstrap.css';
 

function CatalogList({catalogs}){
    return(
        <div className="catalogList-wrap" >
             {catalogs.map((catalog)=>(<CatalogItem catalog={catalog} key={catalog.id}/>
             ))}
        </div>
        
    );
}

export default CatalogList;