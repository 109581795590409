import React from "react"; 
import 'bootstrap/dist/css/bootstrap.css';
import { Link} from "react-router-dom";
 
import {motion} from "framer-motion" 
// import AOS from 'aos';
// import 'aos/dist/aos.css';

const CatalogItem =({
    catalog:{
        id,image,title,urltitle,content,contentLong,imageBig}
    })=>(
        
    //  make title instead the id give each category a titel in the json
         <Link   style={{ textDecoration: 'none'  }}   to={`/catalog/${title.toLowerCase().replace(/\s+/g, '')}`} > 
         <motion.div  className="catalog-card" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.3 }} >
        <img src={image} alt="catalogimgaes" className="img-catalog"></img> 
          <h6  style={{ color: '#000', marginTop: '5px'  }}>{title}</h6>
          <div className="div-p-card-catalog"><p className="catalog-p" style={{color: '#696464'}}>{content} <br></br><span style={{textDecoration: 'underline'}}>read more</span></p></div>
          </motion.div>
          </Link> 
     
    );
 

export default CatalogItem;