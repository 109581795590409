const data =[
    {
        id: 1,
        image:"images/cBlack Seed.png",
        imageBig:"images/pBlack Seed.png",
        title: "Niger Seed",
        content: "Our organic Niger Seed, also known as Nigella Sativa, is native to southwestern Asia",
        contentLong:"Our organic Niger Seed, also known as Nigella Sativa, is native to southwestern Asia and has been used in traditional medicine for thousands of years. It is a rich source of antioxidants, protein, fiber, and healthy fats, and has been shown to have anti-inflammatory properties that may help support overall health."
    },
    {
        id: 2,
        image:"images/cCotton Seed.png",
        imageBig:"images/pCotton Seed.png",
        title: "Cotton Seed",
        content: "Our organic Cotton Seed is native to the Americas and has been cultivated for thousands of years.",
        contentLong:"Our organic Cotton Seed is native to the Americas and has been cultivated for thousands of years. It is a good source of protein, fiber, and healthy fats, and contains various vitamins and minerals, including vitamin E and magnesium, which may help support heart health."
   
     },
    {
        id: 3,
        image:"images/cSesame Seed.png",
        imageBig:"images/pSesame Seed.png",
        title: "Sesame Seed",
        content: "Our organic Sesame Seed is native to Africa and has been cultivated for thousands of years." ,
        contentLong:"Our organic Sesame Seed is native to Africa and has been cultivated for thousands of years. It is a good source of protein, fiber, healthy fats, and various minerals, including calcium and iron, which may help support bone health and overall wellness"
    },
    {
        id:4 ,
        image:"images/cFlax Seed.png",
        imageBig:"images/pFlax Seed.png",
        title: "Flax Seed",
        content: "Our organic Flax Seed is native to the Mediterranean and has been cultivated for thousands of years. ",
        contentLong:"Our organic Flax Seed is native to the Mediterranean and has been cultivated for thousands of years. It is a nutrient-dense oil seed that is rich in omega-3 fatty acids, fiber, and lignans, which are plant compounds that may have anti-cancer properties."

    },
    {
        id: 5,
        image:"images/cSoya Bean.png",
        imageBig:"images/pSoya Bean.png",
        title: "Soybean",
        content: "Our organic Soyabean is native to East Asia and has been cultivated for thousands of years.",
        contentLong:"Our organic Soyabean is native to East Asia and has been cultivated for thousands of years. It is a good source of protein, fiber, healthy fats, and various vitamins and minerals, including iron and calcium, which may help support bone health and overall wellness"

    },
  
    {
        id: 6,
        image:"images/cCastor Seed.png",
        imageBig:"images/pCastor Seed.png",
        title: "Castor Seed",
        content: "Our organic Castor Seed is native to Africa and has been used in traditional medicine for thousands of years",
        contentLong:"Our organic Castor Seed is native to Africa and has been used in traditional medicine for thousands of years. It is a good source of healthy fats, antioxidants, and ricinoleic acid, which has been shown to have anti-inflammatory properties and may help support overall health."

    },
    {
        id: 7,
        image:"images/cMustard Seed.png",
        imageBig:"images/pMustard Seed.png",
        title: "Mustard Seed",
        content: "Our organic Mustard Seed is native to the Mediterranean and has been used for thousands of years"  ,
        contentLong:"Our organic Mustard Seed is native to the Mediterranean and has been used for thousands of years as a condiment and medicinal herb. It is a good source of protein, fiber, and various minerals, including calcium and iron, which may help support bone health and overall wellness."

      },
    {
        id:8 ,
        image:"images/cAvocado.png",
        imageBig:"images/pAvocado.png",
        title: "Avocado",
        content: "Our organic Hass, Nabal, and Fuerte Avocados are native to Central and South America and have been cultivated for thousands of years",
        contentLong: "Our organic Hass, Nabal, and Fuerte Avocados are native to Central and South America and have been cultivated for thousands of years and are now grown across the globe in countries with similar climates. They are a good source of healthy fats, fiber, potassium, and vitamins C and K, which may help support heart health and overall wellness."
        },
    {
        id: 9,
        image:"images/cBlack Seed Edible Oil.png",
        imageBig:"images/pBlack Seed Edible Oil.png",
        title: "Niger Seed Edible Oil",
        content: "Our organic Niger Seed Edible Oil is made from the seeds of the Nigella Sativa plant, which is native to southwestern Asia" ,
        contentLong:"Our organic Niger Seed Edible Oil is made from the seeds of the Nigella Sativa plant, which is native to southwestern Asia. This oil has been used for centuries in traditional medicine and is known for its anti-inflammatory and antioxidant properties. It is often used in cooking and as a dietary supplement to support overall health"

       },
    {
        id: 10,
        image:"images/cBlack Seed Refined Oil.png",
        imageBig:"images/pBlack Seed Refined Oil.png",
        title: "Niger Seed Refined Oil",
        content: "Our organic Niger Seed Refined Oil is made from the same Nigella Sativa seeds as our edible oil" ,
        contentLong:"Our organic Niger Seed Refined Oil is made from the same Nigella Sativa seeds as our edible oil, but goes through a refining process to remove impurities and create a neutral taste. This oil is often used in cosmetic and personal care products for its moisturizing and soothing properties."
  
     },
    {
        id: 11,
        image:"images/cSesame Seed Edible Oil.png",
        imageBig:"images/pSesame Seed Edible Oil.png",
        title: "Sesame Seed Edible Oil",
        content: "Our organic Sesame Seed Edible Oil is made from sesame seeds, which are native to Africa." ,
        contentLong: "Our organic Sesame Seed Edible Oil is made from sesame seeds, which are native to Africa. This oil has a nutty flavor and is often used in cooking and salad dressings. It is a good source of healthy fats and contains various vitamins and minerals, including calcium and iron."
       },
    {
        id: 12,
        image:"images/cFlax Seed Edible Oil.png",
        imageBig:"images/pFlax Seed Edible Oil.png",
        title: "Flax Seed Edible Oil",
        content: "Our organic Flax Seed Oil is made from flax seeds, which are native to the Mediterranean. " ,
        contentLong:"Our organic Flax Seed Oil is made from flax seeds, which are native to the Mediterranean. This oil is a rich source of omega-3 fatty acids, fiber, and lignans, which have been shown to have anti-cancer properties. It is often used as a dietary supplement to support heart health and overall wellness."

       },
    {
        id: 13,
        image:"images/cFlax Seed Refined Oil.png",
        imageBig:"images/pFlax Seed Refined Oil.png",
        title: "Flax Seed Refined Oil",
        content: "Our organic Flax Seed Refined Oil is made from the same flax seeds as our regular flax seed oil",
        contentLong:"Our organic Flax Seed Refined Oil is made from the same flax seeds as our regular flax seed oil, but goes through a refining process to remove impurities and create a neutral taste. This oil is often used in cosmetic and personal care products for its moisturizing and anti-inflammatory properties."

        },
  
   
    {
        id: 14,
        image:"images/cCastor Seed refined oil.png",
        imageBig:"images/pCastor Seed refined oil.png",
        title: "Castor Seed refined oil",
        content: "Our castor seed oil is extracted from the seeds of the castor plant, which is native to Africa and India." ,
        contentLong:"Our castor seed oil is extracted from the seeds of the castor plant, which is native to Africa and India. The refined oil has a pale-yellow color and a mild taste. It is commonly used in the food industry for cooking, baking, and frying due to its high smoke point. Castor oil is also used in various industries such as cosmetics, pharmaceuticals, and manufacturing. It is known for its potential health benefits such as improving digestion, relieving constipation, and promoting hair growth"

    },
    {
        id: 15,
        image:"images/cMustard Seed Edible Oil.png",
        imageBig:"images/pMustard Seed Edible Oil.png",
        title: "Mustard Seed Edible Oil",
        content: "Our mustard seed oil is extracted from the seeds of the mustard plant, which is native to Asia and Europe." ,
        contentLong:"Our mustard seed oil is extracted from the seeds of the mustard plant, which is native to Asia and Europe. The oil has a pungent taste and a yellowish color. It is commonly used in cooking and as a condiment due to its distinct flavor. Mustard oil is also used in traditional medicine for its potential health benefits such as improving heart health, reducing inflammation, and promoting skin and hair health."

    },
    {
        id: 16,
        image:"images/cAvocado Edible Oil.png",
        imageBig:"images/pAvocado Edible Oil.png",
        title: "Avocado Edible Oil",
        content: "Our avocado oil is made from the fruit of the avocado tree, which is native to Central and South America." ,
        contentLong:"Our avocado oil is made from the fruit of the avocado tree, which is native to Central and South America. The oil has a mild flavor and a greenish color. It is commonly used in cooking and as a salad dressing due to its high smoke point and nutritional value. Avocado oil is rich in healthy fats, vitamins, and antioxidants which can potentially benefit heart health, brain function, and skin health."

    },
    {
        id: 27,
        image:"images/cAvocado Butter.png",
        imageBig:"images/pAvocado Butter.png",
        title: "Avocado Butter",
        content: "Our avocado butter is made from the flesh of the avocado fruit. It has a creamy texture and a mild, nutty flavor." ,
        contentLong:"Our avocado butter is made from the flesh of the avocado fruit. It has a creamy texture and a mild, nutty flavor. It is commonly used in the cosmetic industry for its moisturizing properties and as an ingredient in skincare products. Avocado butter is rich in healthy fats, vitamins, and antioxidants which can potentially benefit skin health, hair health, and overall wellbeing."

   }
]

export default data;