import React,{ useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from "./Navbar";
import {CatalogSection,RowNumbersBlueAndGreen,WhytoChose,HowtoWork,BlogSection} from "./HomeSections";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import Footer from "./footer"
import { Link,NavLink} from "react-router-dom";
import { VisionMison,AboutYewahNoImage,AboutYewahNoImageFlib } from "./SectionsAbout";
import {motion} from "framer-motion" 

function Home(){
 
  const navigate = useNavigate();

  
    return  (
        <section id="homesection"> 
        <NavBar/>
        
        <div className="landing-image-home" ></div>
       

          <AboutYewahNoImage  title1="Best Quality" title2="from Africa" 
              paragraph1="Welcome to Yewah Organics, an agriculture company based in Ethiopia that is committed to bringing the organic living and cultivating procedures of Africa to the world." 
              paragraph2="At Yewah Organics, we take pride in the quality of our development and are dedicated to ensuring that our products are of the highest quality, from cultivation to end consumers." 
              // paragraph3="from cultivation to end consumers." 

        />


          <VisionMison mision="Our Mission" paragraphM="Our mission is to deliver organic products to individuals that desire a better lifestyle" vision="Our Vision" paragraphV="Our vision is to improve individuals' energy, mindset, and lifestyle through organic living."/>
         
        

           <AboutYewahNoImageFlib  title1="Excellence in Organic" title2="Agriculture"  
        paragraph1="We believe that the key to producing high-quality organic products is in the cultivation process, which is why we take great care in every step of the process." 
        paragraph2="Our farms are in fertile regions of Ethiopia that provide optimal growing conditions for our crops." 
        paragraph3="We have dedicated teams of experts who work tirelessly to ensure that our crops are healthy, thriving, and of the highest quality." 

           /> 

        
        <div className="container-fluid-sections-statics home-grey-statistics"> 
         <div className="row row-statistics">
         <h1 className="h1-home-number">Why Trust Yewah Organics?</h1>
        <div className="col-lg-3 col-md-4 col-statistics-home "><RowNumbersBlueAndGreen startNumber="0" EndNumber="20" sign="+" title="Country Scope"   id="IDBlueNumbersHome"/></div>
        <div className="col-lg-3 col-md-4 col-statistics-home "><RowNumbersBlueAndGreen startNumber="0" EndNumber="20" sign="+" title="Years Of Experince"  id="IDgreenNumbersHome" /></div>

           </div>
           </div>
  
         <section id="whyChoose">
         <div className="container-whychoose">
         <h1 className="h1-home-number">Why Choose Yewah Organics?</h1> 
         <div className="row row-whychoose">
         <div className="col-lg-3 col-md-4 col-whychoose"><WhytoChose title="100% Organic" src="images/Green Leave.svg"/></div>
         <div className="col-lg-3 col-md-4 col-whychoose"><WhytoChose title="Certified Products" src="images/Verified.svg"/></div>
         <div className="col-lg-3 col-md-4 col-whychoose"><WhytoChose title="Eco-Friendly" src="images/CO2.svg"/></div>
         <div className="col-lg-3 col-md-4 col-whychoose"><WhytoChose title="Zero Toxic Chemicals" src="images/No Chemical.svg"/></div>
         </div>
         <NavLink to={"/catalog"}><motion.button type="button" className="btn btn-primary btn-lg btn-whychoose" whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>Browse all Products</motion.button></NavLink>
         </div>
         </section>

         <section id="howTOwork">
         <div className="container-fluid-sections-statics container-howTowork">
         <div className="row row-howtoWork">
         <div className="col-lg-6 col-md-12 img-div-seeds">
               <img alt="homeworkimg" className ="img-howtoWork" src="images/Yewah Seeds Process Section (2).png"></img>
         </div>
         <div className="col-lg-6 col-md-12">
         <h1 className="h1-howtoWork">How to work with Yewah Organics?</h1> 
         <p className="p-howtoWork">Our Process is very simple. It goes as follows:</p>
         <div className="row row-howtoWork" style={{marginTop:"30px"}}><HowtoWork title="Contact our sales team" texts="to be provided with a catalog, product information, and order requirements"/></div>
         <div className="row row-howtoWork"><HowtoWork title="Select the products" texts="and place your order with our sales team."/></div>
         <div className="row row-howtoWork"><HowtoWork title="Receive your order" texts="according to the delivery schedule provided."/></div>
         <Link to={"/about#howitworks"}><motion.button  className="btn btn-secondary" whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} >See the entire process</motion.button></Link>
        </div>
         </div>
         </div>
         </section>
         
        
         <BlogSection/>
         <CatalogSection/>
        
         <Footer/>

        </section>
       
    );
}

export default Home;