import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/dropdown';
import { Link,NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
// import logo from '/images/MainLogo.svg'
 

function Navigationbar(){
  return(
    
    <div className="stickyNav">
     
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top" style={{paddingTop:"0px"}}>
         
  <div className="container-fluid">
     <Link to={"/"}><img className="navbar-brand" src= "images/MainLogo.svg"/></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
      <NavLink  className="navlink" activeclassname="active" to={"/"}><li className="nav-item">
                 <p className="nav-link ">Home</p>
        </li></NavLink>

        <NavLink   className="navlink"  activeclassname="active" to={"/about"}><li className="nav-item">
                 <p className="nav-link ">About us</p>
        </li></NavLink>

        <NavLink   className="navlink"  activeclassname="active" to={"/catalog"}><li className="nav-item">
              <p className="nav-link ">Catalog</p> 
        </li></NavLink>

        <HashLink  className="navlink"  activeclassname="active" to={"/about#howitworks"}><li className="nav-item">
                 <p className="nav-link ">How it works</p> 
        </li></HashLink>

        <NavLink   className="navlink" activeclassname="active" to={"/contact"}><li className="nav-item">
                    <p className="nav-link ">Contact us</p> 
        </li></NavLink>
           
         <NavLink   className="navlink"  activeclassname="active" to={"/Booking"}><li className="nav-item">
                                 <p className="nav-link ">Book a meeting</p> 
        </li></NavLink>
 
       
      </ul>
       
    </div>
  </div>
  
</nav>
    </div>
    

  );
}

export default Navigationbar;
