import React, { useEffect } from "react";
import NavBar from "./Navbar";

const TidyCalEmbed = () => {
    // const tidydata = process.env.REACT_APP_DATA;
    // React.useEffect(() => {
    //   const head = document.querySelector('head');
    //   const script = document.createElement('script');
    //   script.setAttribute('src',  process.env.REACT_APP_DATA );
    //   head.appendChild(script);
  
    //   return () => {
    //     head.removeChild(script);
    //   };
    // }, );
  
    return (
    
      //   <div style={{marginTop:'100px'}}> 
      //   <NavBar/>
      //   <div className="tidycal-div"
      //   id="tidycal-embed"
      //   data-path=  {process.env.REACT_APP_PATH}
      
      // /> 
    
      // </div>

      <div class="iframe-container">
      <NavBar/>
     <iframe src="https://tidycal.com/3le5xn1/yewah-discovery-meeting"></iframe>
    </div>
      
    );
  };

 
export default TidyCalEmbed;
