import React, { useEffect } from "react";
import { useParams,Link } from "react-router-dom";
import { useState } from "react";
import data from "../../src/catalogData";
 
import 'bootstrap/dist/css/bootstrap.css';
 
 
import NavBar from "./Navbar";
 
import {motion} from "framer-motion"



const  CatalogPages=()=>{
    const{title}=useParams(); //id
    const [catalog, setcatalog] = useState(null);

   useEffect(()=>{
        let catalog=data.find(catalog=>catalog.title.toLowerCase().replace(/\s+/g, '') === title);
        // let next = (catalog.id)+1;
      //  console.log(next)
        if(catalog){
            setcatalog(catalog);
        }
         
   }, )

    return(
        <div  > 
         <NavBar/>
       

       {
        catalog ? (

            <div className="container-fluid-sections-catlaog-page">
            <Link className="blog-goBack" to="/catalog"><span>&#8592;</span> Go Back</Link>
          <motion.div className="row Catalog-wrap  outer-div-catalog" initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.8, delay: 0.5, ease: [0, 0.71, 0.2, 1.01]}}>
            <div className="col-lg-6 col-md-6  div-img-catalog">
            <img className="img-catalog-card" src={catalog.imageBig} alt="catalogcard"></img>
            </div>
   
            <div className="colm col-lg-6 col-md-12 col-sm-12 ">
            <div className="col-catalog-content-page "> 
            <h1 className="h1-catalog-page">{catalog.title}</h1>
            <p className="blog-desc"  >{catalog.contentLong}</p>
            </div>
            <Link to={"/contact"}><motion.button className="btn btn-secondary"  style={{marginLeft:'15px'}} whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>Inquire Now!</motion.button></Link>
            </div>
          </motion.div>
        </div>

            
        ): (null)}
       
       
        
       </div>
       
    );
}

export default CatalogPages;