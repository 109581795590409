import {React , useState}from "react";
import 'bootstrap/dist/css/bootstrap.css';
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import 'react-vertical-timeline-component/style.min.css'
import {motion} from "framer-motion";
import TypewriterComponent from "typewriter-effect";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

function RowNumbersBlueAndGreen(props){
    const [counterOn, setCounterOn] = useState(false);
     return(
       
      <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}> 
      <div>
      <h1  id={props.id} className="num-statistic-home">{counterOn && <CountUp start={props.startNumber} end={props.EndNumber} duration={3} delay={0}/>}{props.sign}</h1>
      <p className="p-statistic-home">{props.title}</p>
      </div>
      </ScrollTrigger>
     );
   }


function WhytoChose(props){
         return(
         <div className="box-content-whychoose"  initial={{ opacity: 0, scale: 0.5 }}
        //  animate={{ opacity: 1, scale: 1 }}
        //  transition={{
        //    duration: 0.3,
        //    ease: [0, 0.71, 0.2, 1.01],
        //    scale: {
        //      type: "spring",
        //      damping: 5,
        //      stiffness: 100,
        //      restDelta: 0.001
        //    }
        //  }}
         data-aos="flip-down">
            <img alt="whychooseimg" className="img-whychoose" src={props.src}></img>
            <p className="p-whychoose">{props.title}</p>
         </div> 
         ); 
  } 


function HowtoWork(props){
   return(
    <div className="col col-howtoWork"><h2 className="h1-col-howtoWork">{props.title}</h2><p className="p-col-howtoWork">{props.texts}</p></div>

   );
}  

 


function CatalogSection(){
   return(
   <section  id="catalog-home">
   <div className="container-fluid-sections-homeC grey-homeLastsec"> 
     <div className="outer-div-catalogHome">
        <div className="grid-home">
          <div className="h1-div-home-catalog">
            <h1 className="h1-catalog-home">Get To Know Us More, Head Up To Our Catalog Page Now!</h1>
            <Link to={"/catalog"}><motion.button className="button-div-catalog-home " whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>Go to catalog</motion.button></Link>
          </div>
          <div className=" col-images-catalog-home  ">
          <div className=" inner-images-catalog-home  ">
            <motion.div className="firstimage"  animate={{
      y: [-10, 10, -10],
    }}
    transition={{
      duration: 4,
      repeat: Infinity,
      repeatType: "loop",
      ease: "easeInOut",
    }}></motion.div>
            
            
            <motion.div className="secondimage"  animate={{
      y: [-10, 10, -10],
    }}
    transition={{
      duration: 4,
      repeat: Infinity,
      repeatType: "loop",
      ease: "easeInOut",
    }}></motion.div>
            <motion.div className="thirdimage"  animate={{
      y: [-10, 10, -10],
    }}
    transition={{
      duration: 4,
      repeat: Infinity,
      repeatType: "loop",
      ease: "easeInOut",
    }}></motion.div>
            </div>
            </div>
            </div>
        </div>
     </div>
      
   </section>
   );

}

function BlogSection(){
  return(
  <section  id="blog-home">
  <div className="container-fluid-sections-homeC"> 
    <div className="outer-div-blogHome">
       <div className="grid-blog">
         
         <div className=" col-images-blog-home  ">
         <div className=" inner-images-blog-home  ">
     
           
           
           <motion.div className="secondimageblog"  animate={{
     y: [-10, 10, -10],
   }}
   transition={{
     duration: 4,
     repeat: Infinity,
     repeatType: "loop",
     ease: "easeInOut",
   }}></motion.div>
           <motion.div className="thirdimageblog"  animate={{
     y: [-10, 10, -10],
   }}
   transition={{
     duration: 4,
     repeat: Infinity,
     repeatType: "loop",
     ease: "easeInOut",
   }}></motion.div>
           </div>
           </div>


           <div className="h1-div-home-blog">
           <h1 className="h1-blog-home">Get the latest news and tips on our wide selection by visiting our informative blog website to see more of our organic   
        <TypewriterComponent
        options={{
           autoStart: true,
           loop: true,
           delay: 30,
           strings:[
            " seeds 🌱",
            " oil 🌻",
            " fruit 🍎"
           ],
        }}/>
         </h1>
         <a href="https://yewahlife.com/" target="_blank" style={{textDecoration: "none"}}> 
           <motion.button   className="button-div-blog-home "  whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>Go to Blog</motion.button>
           </a>
         </div>
           </div>
       </div>
    </div>
     
  </section>
  );

}


   export {CatalogSection,RowNumbersBlueAndGreen,WhytoChose,HowtoWork,BlogSection};