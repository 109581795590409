import React from "react";
import NavBar from "./Navbar";
import data from "../../src/catalogData";
import Footer from "./footer"
import 'bootstrap/dist/css/bootstrap.css';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowPointer} from "@fortawesome/free-solid-svg-icons"
import CatalogList from "./CatalogList";
import {motion} from "framer-motion" 

 

function Catalog(){ //Blog
    return(
        <section id="catalog-section"> 
        <NavBar/>
         <div className="container-fluid-sections-catalog container-catalog">
         <h1 className="h1-catalog">Browse Yewah’s Products</h1>
         <h6 className="h5-top-catalog">Let's get started! Get in touch to <NavLink style={{ textDecoration: 'none', color: '#696464' }} to={"/contact"}><span className="button-top">Inquire Now <FontAwesomeIcon icon={faArrowPointer} /></span> </NavLink></h6>
         <div className="row row-catalog" align="center">
             <CatalogList catalogs={data}/>
        
         </div>
         <NavLink to={"/contact"}><motion.button type="button" className="btn btn-lg catalog-button" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>Let's get started! Get in touch to order now <img alt="arrowimg" className="arrow" src="images/arrow-right-long-solid.svg"></img></motion.button></NavLink>
         </div>
         
          
         <Footer/>
         </section>
    );
}

export default Catalog;