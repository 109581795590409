import React from "react";
import {footer} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTwitter,faInstagram, faYoutube, faLinkedin} from "@fortawesome/free-brands-svg-icons"



function Footer(){
    return(
        <footer className="bg-lite text-center text-dark footer">
     
        <div className="container p-4 pb-0 container-footer">
         
          
           <div className="icons-footer"> 
             
            <a className="btn btn-outline-dark  btn-floating m-1" href="https://twitter.com/yewahorganics" target="_blank" role="button"
              ><FontAwesomeIcon icon={faTwitter} /></a>
      
            
            <a className="btn btn-outline-dark  btn-floating m-1" href="https://www.instagram.com/yewahorganics/" target="_blank" role="button"
              ><FontAwesomeIcon icon={faInstagram} /></a>
      
       
            <a className="btn btn-outline-dark  btn-floating m-1" href="https://www.linkedin.com/company/yewah-organics" target="_blank" role="button"
              ><FontAwesomeIcon icon={faLinkedin} /></a>
      
           
            <a className="btn btn-outline-dark  btn-floating m-1" href="https://www.youtube.com/@YewahOrganics" target="_blank" role="button"
              ><FontAwesomeIcon icon={faYoutube} /></a>
 
         </div>
          
        </div>
        
      
       
        
      </footer>
    );
}








 export default Footer;