import React, {useEffect} from "react";
import NavBar from "./Navbar";
import 'bootstrap/dist/css/bootstrap.css';
import {AboutYewahNoImageAnimate,TimeLine, TimeLine2,AboutYewahNoImage, VisionMison, RowNumbers,AboutYewahNoImageFlib, AboutYewahWithImage} from "./SectionsAbout";
import Footer from "./footer";
import {motion} from "framer-motion"
 
function About(){
   

    return  (
        <section id="aboutPage"> 
        <NavBar/>
        <div className="landing-image-About" ></div>
        {/* <motion.div className="landing-image" initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.8, delay: 0.5, ease: [0, 0.71, 0.2, 1.01]}}><img alt ="aboutimage" className="image-landingAbout" src="images/aboutLanding2.png"></img></motion.div> */}
        <AboutYewahNoImage   title1="About" title2="Yewah" 
        paragraph1="Yewah Organics is an organic agriculture company based in Ethiopia, specializing in the production and distribution of high-quality organic oil seeds and avocados both domestically and internationally." 
        paragraph2="We take pride in our commitment to sustainable agriculture practices and our unwavering focus on delivering premium quality products to our customers." 
        // paragraph3=" " 

        />
         
         <VisionMison mision="Our Mission" paragraphM="Our mission is to deliver organic products to individuals that desire a better lifestyle" vision="Our Vision" paragraphV="Our vision is to improve individuals' energy, mindset, and lifestyle through organic living."/>
         <div className="container-fluid-sections-statics"> 

         <div className="row row-statistics">
             <div className="col-lg-3 col-md-6 col-sm-6 col-statistics "><RowNumbers startNumber="0" EndNumber="15" sign="+" title="Organic Products"/></div>
             <div className="col-lg-3 col-md-6 col-sm-6 col-statistics"><RowNumbers startNumber="0" EndNumber="20" sign="+" title="Years Of Experience"/></div>
             <div className="col-lg-3 col-md-6 col-sm-6 col-statistics">  <RowNumbers startNumber="0" EndNumber="100" sign="%" title="Locally Sourced"/></div>
             <div className="col-lg-3 col-md-6 col-sm-6 col-statistics">  <RowNumbers startNumber="0" EndNumber="20" sign="+" title="Country Scope"/></div>  
         </div>
         </div>
         <AboutYewahNoImageFlib id="grey-sec-noimgflib" title1="Locally" title2="​Cultivated"  
        paragraph1="Our journey begins with our farms in Ethiopia, where we cultivate high-quality organic products using traditional farming practices passed down from generation to generation." 
        paragraph2="We use only natural inputs such as compost, animal manure, and cover crops to enrich the soil and promote healthy plant growth." 
        paragraph3="Our crops are carefully monitored to ensure that they are free from harmful chemicals and pesticides." 

           /> 


    <AboutYewahNoImageAnimate  title1="No Additives " title2="Modifications" 
         paragraph1="Our products are grown and processed using traditional farming practices that have been passed down from generation to generation."
         paragraph2="We work closely with small-scale farmers in Ethiopia who share our passion for organic farming and who use natural inputs such as compost, animal manure, and cover crops to enrich the soil and promote healthy plant growth."
         paragraph3="Our farmers are trained to monitor their crops carefully to ensure that they are free from harmful chemicals and pesticides."

        />
        
        <AboutYewahWithImage  id="grey-sec-noimgflib" title="Quality" title2="Guaranteed" 
        paragraph1="At Yewah Organics, we are committed to producing the highest quality oil seeds and avocados in Ethiopia. We take great care in every step of the process, from cultivation to processing and distribution, to ensure that our products are of the highest quality. ​" 
        paragraph2="Our farms are in fertile regions of Ethiopia that provide optimal growing conditions for our crops. We have dedicated
                    teams of experts who work tirelessly to ensure that our crops are healthy, thriving, and of the highest quality."/>
       
        






        <AboutYewahNoImageAnimate  title1="Farm-to Consumer​" title2=" ​Model" 
           
           paragraph1="At Yewah Organics, we believe that the future of food and life is organic, and we are dedicated to playing our part in creating a more sustainable and healthier world."
           paragraph2="Join us on our mission to bring the organic living and cultivating procedures of Africa to the world, one farm-to-table meal at a time, with our unwavering commitment to quality."
        //    paragraph3=""

           />


           

        
        <section id="howitworks"> 
        <div className="container-fluid-sections-timeline timeline1-grey"> 
        <h3 className="road-h3">How to work with Yewah Organics?</h3><h6 style={{textAlign:'center', marginTop:'15px'}}>For Domestic Customers</h6>
        
         <TimeLine/>
           </div>
           </section>

           <section id="timeline-section2"> 
        <div className="container-fluid-sections-timeline"> 
        <h3 className="road-h3" style={{color:"#7AA874"}}>How to work with Yewah Organics?</h3><h6 style={{textAlign:'center', marginTop:'15px', color:"#42855B"}}>For International Customers</h6>
      
         <TimeLine2/>
           </div>
           </section>
         
           <Footer/>
        </section>
        
       
    );
}

export default About;